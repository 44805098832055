<template>
  <div>
    <app-page-detail page-title="Detail MJO" page-toolbar-title="Detail MJO">

      <template #toolbar v-if="!notFound">
        <app-button @click="getDetail(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button>

        <app-log-data-dialog module_name="mjo" :id="mjo_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>

        <app-button @click="redirect('Administration.MJO.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
        <v-divider vertical class="mx-2"></v-divider>

        <app-button :disabled="!enableEditBtn" @click="redirect('Administration.MJO.Edit', { id: id })" title="Edit" mdi-icon="mdi-pencil"></app-button>
        <app-button :disabled="!enableUpdateBtn" @click="redirect('Administration.MJO.Update', { id: id })" title="Update" mdi-icon="mdi-file-document-edit-outline"></app-button>
        <app-button :disabled="!enableDeleteBtn" @click="deleteData()" :loading="loading_delete" title="Delete Document" mdi-icon="mdi-delete"></app-button>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button :disabled="!enableSendApprovalBtn" @click="sendApproval()" :loading="loading_send_approval" title="Send Approval" mdi-icon="mdi-file-send"></app-button>
        <app-button :disabled="!enableApproveBtn" @click="approveData()" :loading="loading_approve" title="Approve Document" mdi-icon="mdi-file-check"></app-button>
        <app-button :disabled="!enableReceiveBtn" @click="receiveData()" :loading="loading_receive" title="Receive Document" mdi-icon="mdi-checkbox-marked-outline"></app-button>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button :disabled="!enableCancelBtn" @click="cancelData()" :loading="loading_cancel" title="Cancel Document" mdi-icon="mdi-file-document-remove"></app-button>
        <app-button :disabled="!enableReopenBtn" @click="reopenData()" :loading="loading_reopen" title="Re-Open Document" mdi-icon="mdi-file-restore"></app-button>
        <app-button :disabled="!enableCloseBtn" @click="closeData()" :loading="loading_close_document" title="Closed Document" mdi-icon="mdi-book-check"></app-button>
      </template>

      <v-row v-if="!notFound">
        <v-col cols="12" lg="9">
          <!-- General -->
          <card-expansion title="General" class="mb-3" :isMinimize="false">
            <v-row>
              <v-col cols="6" md="6">
                <v-text-field dense disabled v-model="mjo_header.document_no_" placeholder="Doc. No." label="Doc. No." readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense disabled v-model="mjo_header.document_date" placeholder="Doc. Date" label="Doc. Date" readonly filled outlined hide-details class="mb-2" type="date"></v-text-field>
                <v-select dense disabled v-model="mjo_header.company_id" :items="list_company" item-text="label" item-value="value" placeholder="Company ID" label="Company ID" readonly filled outlined hide-details class="mb-2"></v-select>
                <v-text-field dense disabled v-model="mjo_header.area_code" placeholder="Area Code" label="Area Code" readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-select disabled readonly filled :items="$app_options.mjo.priority" dense v-model="mjo_header.priority" placeholder="Prioritas" label="Prioritas" outlined hide-details class="mb-2"></v-select>
                <v-select disabled readonly filled :items="$app_options.mjo.job_type" dense v-model="mjo_header.job_type" placeholder="Tipe Pekerjaan" label="Tipe Pekerjaan" outlined hide-details class="mb-2"></v-select>
                <v-text-field disabled readonly filled type="datetime-local" dense v-model="mjo_header.damage_at" placeholder="Tanggal Kerusakan" label="Tanggal Kerusakan" outlined hide-details class="mb-2"></v-text-field>
              </v-col>
            </v-row>
            <v-textarea disabled readonly filled dense v-model="mjo_header.problem_description" placeholder="Detil Masalah" label="Detil Masalah" outlined hide-details class="mb-2" height="100"></v-textarea>
          </card-expansion>
          <card-expansion title="Fixed Asset" class="mb-3" :isMinimize="true">
            <FixedAssetDetail :mjo_header="mjo_header"></FixedAssetDetail>
          </card-expansion>
          <card-expansion title="Images" class="mb-3" :isMinimize="true">
            <Images :mjo_header="mjo_header"></Images>
          </card-expansion>
          <card-expansion title="Problem Identification" class="mb-3" :isMinimize="true">
            <ProblemIdentification :mjo_header="mjo_header"></ProblemIdentification>
          </card-expansion>
          <card-expansion title="Corrective Acction" class="mb-3" :isMinimize="true">
            <CorrectiveAction2 :id="mjo_header.id"></CorrectiveAction2>
          </card-expansion>
          <card-expansion title="Approval" class="mb-3" :isMinimize="true" no-padding>
            <ApprovalList v-bind:data-list="mjo_approval" :hideToolbar="true"></ApprovalList>
          </card-expansion>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :mjo_header="mjo_header"></document-info>
          <comment-list :mjo_header_id="parseInt(mjo_header.id)"></comment-list>
        </v-col>
      </v-row>


      <v-alert v-if="notFound" title="tset" type="error">
        <p>Data Not Found!</p>
      </v-alert>
    </app-page-detail>
  </div>
</template>

<script>
import ApprovalList from './components/ApprovalList.vue';
import CommentList from './components/CommentList.vue';
import DocumentInfo from './components/DocumentInfo.vue';
import Images from './components/Images.vue';
import FixedAssetDetail from './components/FixedAssetDetail.vue';
import ProblemIdentification from './components/ProblemIdentification.vue';
import CorrectiveAction2 from './components/CorrectiveAction2.vue';

export default {
  components: {
    CommentList,
    ApprovalList,
    DocumentInfo,
    Images,
    FixedAssetDetail,
    ProblemIdentification,
    CorrectiveAction2,
  },
  data() {
    return {
      id: 0,
      loading: false,
      loading_delete: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_close_document: false,
      loading_approve: false,
      loading_cancel: false,
      loading_attachment: false,
      loading_receive: false,

      notFound: false,

      list_company: [
        { label: 'SBU PRODUCTION', value: 'SBUP' },
        { label: 'SBU SALES', value: 'SBUS' },
      ],

      selected_fa: {},
      fa_loading: false,
      fa_list: [],
      mjo_header: {},
      mjo_approval: [],
      mjo_comment: [],
      mjo_pic: [],
      selected_image: 0,
    };
  },
  watch: {
  },

  computed: {
    enableDeleteBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "approved"])) return false;

      if (this.mjo_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("mjo", "delete") && !this.inArray(this.mjo_header.status, ["approved"]) && this.inArray(this.mjo_header.status, ["open", "cancel"])) {
        return true;
      }
      return false;
    },
    enableReopenBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel", "open"])) return false;

      if (this.mjo_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("mjo", "reopen") && !this.inArray(this.mjo_header.status, ["open"])) {
        return true;
      }
      return false;
    },
    enableCancelBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "cancel") && !this.inArray(this.mjo_header.status, ["approved"]) && !this.inArray(this.mjo_header.status, ["cancel"])) {
        return true;
      }
      return false;
    },
    enableSendApprovalBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "send-approval") && this.mjo_header.status == "open") {
        return true;
      }
      return false;
    },
    enableApproveBtn() {
      if (!this.inArray(this.mjo_header.status, ["waiting-approval"])) return false;

      if (this.mjo_header.status == "waiting-approval") {
        let ap = false;
        if (typeof this.mjo_approval !== "undefined") {
          if (this.mjo_approval.length >= 0) {
            this.mjo_approval.forEach((item) => {
              let approved = item.approved
              if (!approved) {
                if (item.username == this.$store.state.loginInfo.username) {
                  ap = true;
                }
              }
            });
          }
        }

        return ap;
      }
      return false;
    },
    enableEditBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "modify") && this.mjo_header.status == "open") {
        return true;
      }
      return false;
    },
    enableUpdateBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "update") && this.mjo_header.status == "approved" && (this.mjo_header.substatus == "received" || this.mjo_header.substatus == "on-progress")) {
        return true;
      }
      return false;
    },
    enableCloseBtn() {
      if (!this.inArray(this.mjo_header.status, ["approved"])) return false;

      if (!this.inArray(this.mjo_header.substatus, ["", "on-progress"])) return false;

      if (this.modulePermission("mjo", "close")) {
        return true;
      }

      return false;
    },
    enableSetPicBtn() {
      if (!this.inArray(this.mjo_header.status, ["approved"])) return false;

      if (this.modulePermission("mjo", "set-pic") && this.mjo_header.status == "approved") {
        return true;
      }
      return false;
    },
    enableReceiveBtn() {
      if (!this.inArray(this.mjo_header.status, ["approved"])) return false;

      if (this.modulePermission("mjo", "receive") && this.mjo_header.received == false) {
        return true;
      }

      return false;
    },
  },
  
  methods: {

    refreshDetail() {
      this.getDetail(true);
    },

    async getDetail(refresh = false) {
      if (refresh == true)
        this.AxiosStorageRemove("GET", "mjo/detail");

      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
      };
      await this.$axios
        .get("mjo/detail", config)
        .then((res) => {
          this.mjo_header = res.data.data.mjo_header;
          this.mjo_approval = res.data.data.mjo_approval;
          this.mjo_comment = res.data.data.mjo_comment;
          this.mjo_pic = res.data.data.mjo_pic;
          this.user = res.data.data.user;

          this.selected_fa.no_ = this.mjo_header.fa_no_;
          this.selected_fa.description = this.mjo_header.fa_description;
          this.selected_fa.description2 = this.mjo_header.fa_description2;
          this.selected_fa.fa_subclass_code = this.mjo_header.fa_fa_subclass_code;
          this.selected_fa.area_code = this.mjo_header.fa_area_code;
          this.selected_fa.location_code = this.mjo_header.fa_location_code;
          this.selected_fa.fa_location_code = this.mjo_header.fa_fa_location_code;
          this.selected_fa.responsibility = this.mjo_header.fa_responsibility;
          this.selected_fa.serial_no_ = this.mjo_header.fa_serial_no_;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != 'undefined') {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.showLoadingOverlay(false);
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    /**
     * deleteData
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Cancel/Open
     */
    deleteData() {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        await this.$axios
          .delete("mjo/delete/" + this.mjo_header.id)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.goBack()
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Delete document?", post);
    },

    /**
     * cancelData
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Open
     */
    cancelData() {
      let post = async () => {
        this.loading_cancel = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/cancel", formData)
          .then((res) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.goBack()
          })
          .catch((error) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Cancel document?", post);
    },

    /**
     * sendApproval
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Open
     */
    sendApproval() {
      let post = async () => {
        this.loading_send_approval = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/send-approval", formData)
          .then((res) => {
            this.loading_send_approval = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.getDetail()
          })
          .catch((error) => {
            this.loading_send_approval = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Send Approval?", post);
    },

    /**
     * approveData
     * Method untuk meng-approved dokumen
     * Dokumen harus berstatus Waiting Approval
     */
    approveData() {
      let post = async () => {
        this.loading_approve = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/approve", formData)
          .then((res) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Approve document?", post);
    },

    /**
     * reopenData
     * Method untuk re-open dokumen
     * Dokumen di kembalikan ke status Open
     */
    reopenData() {
      let post = async () => {
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/reopen", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Re-open document?", post);
    },

    /**
     * closeData
     * Method untuk re-open dokumen
     * Dokumen di kembalikan ke status Open
     */
    closeData() {
      let post = async () => {
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/close", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Close document?", post);
    },

    receiveData() {
      let post = async () => {
        this.loading_receive = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/receive", formData)
          .then((res) => {
            this.loading_receive = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.getDetail(true);
          })
          .catch((error) => {
            this.loading_receive = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Receive document?", post);
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
};
</script>
